@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  /* override the base font size on which rem units are calculated */
  font-size: 14px;
}

@layer base {

  html {
    @apply bg-ur-primary-background;
  }

  /* respect safe area margins on mobile */
  .px-4-safe-area {
    padding-left: max(1rem, env(safe-area-inset-left));
    padding-right: max(1rem, env(safe-area-inset-right));
  }

  /* apply custom styling from the urbanage guide */

  h1 {
    @apply text-3xl;
  }

  h2 {
    @apply text-2xl;
  }

  h3 {
    @apply text-xl;
  }

  h4 {
    @apply text-lg;
  }

  h5 {
    @apply text-urlg;
  }


  button {
    @apply font-bold text-sm leading-7;
    min-width: 64px;
  }
}
